<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :sm="16" :xs="24">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">   {{ $t("message.sick_leaves") }}</div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  <div class="mx-3">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                  </div>
                  <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.from_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.from')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.before')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :sm="8" :xs="24" class="flex-style text-right mbtop5">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              @c-change="updateColumn"
              :can_create="false"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>

              <th v-if="columns.manager_id.show">
                {{ columns.manager_id.title }}
              </th>

              <th v-if="columns.reason.show">
                {{ columns.reason.title }}
              </th>

              <th v-if="columns.comment.show">
                {{ columns.comment.title }}
              </th>

              <th v-if="columns.status.show">
                {{ columns.status.title }}
              </th>

              <th v-if="columns.left_date.show">
                {{ columns.left_date.title }}
              </th>

              <th v-if="columns.visit_date.show">
                {{ columns.visit_date.title }}
              </th>

              <!-- <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th> -->
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

               <th v-if="columns.staff_id.show">
                <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                <!-- <crm-input
                  :placeholder="columns.staff_id.title"
                  v-model="filterForm.staff_id"
                ></crm-input> -->
              </th>

              <th v-if="columns.manager_id.show">
                <select-staff
                      v-model="filterForm.manager_id"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      :id="filterForm.manager_id"
                    ></select-staff>
                <!-- <crm-input
                  :placeholder="columns.manager_id.title"
                  v-model="filterForm.manager_id"
                ></crm-input> -->
              </th>

              <th v-if="columns.reason.show">
                <!-- <crm-input
                  :placeholder="columns.reason.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.reason"
                ></crm-input> -->
                 <el-select
                      v-model="filterForm.reason_id"
                      :placeholder="columns.reason.title"
                      filterable
                      clearable
                      :class="mode ? 'filter__day' : 'filter__night'"
                      :size="'small'"
                      class="d-block"
                    >
                      <el-option
                        v-for="(reason) in reasons"
                        :key="'guestreason-' + reason.id"
                        :label="reason.name"
                        :value="reason.id"
                      ></el-option>
                    </el-select>
              </th>

              <th v-if="columns.comment.show">
                <crm-input
                  :placeholder="columns.comment.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.comment"
                ></crm-input>
              </th>

              <th v-if="columns.status.show">
                <crm-input
                  :placeholder="columns.status.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.status"
                ></crm-input>
              </th>

              <th v-if="columns.left_date.show">
                <crm-date-picker
                  :placeholder="columns.left_date.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.left_date"
                ></crm-date-picker>
              </th>

              <th v-if="columns.visit_date.show">
                <crm-date-picker
                  :placeholder="columns.visit_date.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.coming_date"
                ></crm-date-picker>
              </th>

              <!-- <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th> -->
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="complaint in list"
              :key="complaint.id"
              class="cursor-pointer"
            >
              <td v-if="columns.id.show">{{ complaint.id }}</td>

              <td v-if="columns.staff_id.show">
                {{
                  complaint.staff
                    ? complaint.staff.name + " " + complaint.staff.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.manager_id.show">
                {{
                  complaint.manager
                    ? complaint.manager.name + " " + complaint.manager.last_name
                    : ""
                }}
              </td>

              <td v-if="columns.reason.show">
                {{ complaint.reason.name }}
              </td>

              <td v-if="columns.comment.show">
                {{ complaint.comment }}
              </td>

               <td v-if="columns.status.show">
                <div class="d-flex">
                  <el-button v-if="complaint.status == 'waiting'" type="warning" size="medium"    class="remove-button"> {{$t('message.confirm')}}</el-button>
                  <el-button v-if="complaint.status == 'accept'" type="success" size="medium"    class="remove-button"> {{$t('message.approved')}}</el-button>
                  <el-button v-if="complaint.status == 'accept'&& complaint.coming_date > getDate()" type="primary" size="medium"  @click="backSickLeave(complaint.id)"     class="remove-button"> {{$t('Tatildan qaytarish')}}</el-button>  
                  <el-button v-if="complaint.status == 'back_work'" type="info" size="medium"    class="remove-button"> {{$t('Ishga qaytarilgan')}}</el-button>
                  <el-button v-if="complaint.status == 'deny'" type="danger" size="medium" class="remove-button"> {{$t('message.rejected')}}</el-button>      
                </div>
              </td>

              <td v-if="columns.left_date.show">
                {{ complaint.left_date }}
              </td>

              <td v-if="columns.visit_date.show">
                {{ complaint.coming_date }}
              </td>

              <!-- <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="complaint"
                                    :actions="actions"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td> -->
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import moment from "moment";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list],

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      list: "sickLeave/list",
      reasons: "sickLeave/reasons",
      columns: "sickLeave/columns",
      pagination: "sickLeave/pagination",
      statues: "sickLeave/statues",
      filter: "sickLeave/filter",
      sort: "sickLeave/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
   mounted() {
    this.reasonsAction();
  },
  methods: {
    ...mapActions({
      updateList: "sickLeave/sickLeave",
      upadetStatus:"sickLeave/upadetStatus",
      reasonsAction: "sickLeave/reasons",
      setPagination: "sickLeave/setPagination",
      updateSort: "sickLeave/updateSort",
      updateFilter: "sickLeave/updateFilter",
      updateColumn: "sickLeave/updateColumn",
      updatePagination: "sickLeave/updatePagination",
      show: "sickLeave/show",
      empty: "sickLeave/empty",
      delete: "sickLeave/destroy",
      refreshData: "sickLeave/refreshData",
    }),
    getDate(){
      return moment(new Date()).format("YYYY-MM-DD")
    },
    backSickLeave(id){
      this.$confirm('Haqiqatdan ham ushbu xodimni kasallik tatilidan qaytarmoqchimisiz?', 'Ogohlantirish', {
          confirmButtonText: i18n.t('message.confirm'),
          cancelButtonText: i18n.t('message.cancel'),
          type: 'warning'
        }).then(() => {
          this.upadetStatus(id).then((res) => {
                            this.refreshData();
                            this.$message({
                            type: 'success',
                            message: 'Xodim ishga qaytarildi!'
                          });
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
        
        }).catch(() => {
          this.$message({
            type: 'info',
            message: "Xarakat bekor qilindi!"
          });   
      });
    }
  },
};
</script>
